import { BlockColors } from "./color-mapping"


const JavascriptBlockToolBox = {
    "kind": "categoryToolbox",
    "contents": [
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "lists_create_empty"
                },
                {
                    "kind": "BLOCK",
                    "type": "lists_create_with"
                },
                {
                    "kind": "BLOCK",
                    "type": "lists_repeat"
                },
                {
                    "kind": "BLOCK",
                    "type": "lists_length"
                },
                {
                    "kind": "BLOCK",
                    "type": "lists_isEmpty"
                },
                {
                    "kind": "BLOCK",
                    "type": "lists_indexOf"
                },
                {
                    "kind": "BLOCK",
                    "type": "lists_getIndex"
                },
                {
                    "kind": "BLOCK",
                    "type": "lists_getSublist"
                },
                {
                    "kind": "BLOCK",
                    "type": "lists_sort"
                },
                {
                    "kind": "BLOCK",
                    "type": "lists_split"
                },
                {
                    "kind": "BLOCK",
                    "type": "lists_reverse"
                },
            ],
            "name": "Lists",
            "colour": BlockColors['Buzzer']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "controls_if"
                },
                {
                    "kind": "BLOCK",
                    "type": "logic_compare"
                },
                {
                    "kind": "BLOCK",
                    "type": "logic_operation"
                },
                {
                    "kind": "BLOCK",
                    "type": "logic_negate"
                },
                {
                    "kind": "BLOCK",
                    "type": "logic_boolean"
                },
                {
                    "kind": "BLOCK",
                    "type": "logic_null"
                },
                {
                    "kind": "BLOCK",
                    "type": "logic_ternary"
                },
            ],
            "name": "Logic",
            "colour": BlockColors['Conditions']
        },
        // {
        //     "kind": "CATEGORY",
        //     "contents": [
        //         {
        //             "kind": "BLOCK",
        //             "type": "controls_repeat_ext"
        //         },
        //         {
        //             "kind": "BLOCK",
        //             "type": "controls_whileUntil"
        //         },
        //         {
        //             "kind": "BLOCK",
        //             "type": "controls_for"
        //         },
        //         {
        //             "kind": "BLOCK",
        //             "type": "controls_forEach"
        //         },
        //         {
        //             "kind": "BLOCK",
        //             "type": "controls_flow_statements"
        //         },
        //     ],
        //     "name": "Loops",
        //     "colour": BlockColors['Constants']
        // },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "math_number"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_arithmetic"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_single"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_constant"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_number_property"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_change"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_on_list"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_modulo"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_constrain"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_random_int"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_random_float"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_atan2"
                },
            ],
            "name": "Math",
            "colour": BlockColors['Math']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "procedures_defreturn"
                },
                {
                    "kind": "BLOCK",
                    "type": "procedures_callreturn"
                },
                {
                    "kind": "BLOCK",
                    "type": "procedures_callnoreturn"
                },
                {
                    "kind": "BLOCK",
                    "type": "procedures_ifreturn"
                },
            ],
            "name": "Functions",
            "colour": BlockColors['Functions']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "text"
                },
                {
                    "kind": "BLOCK",
                    "type": "text_join"
                },
                {
                    "kind": "BLOCK",
                    "type": "text_append"
                },
                {
                    "kind": "BLOCK",
                    "type": "text_length"
                },
                {
                    "kind": "BLOCK",
                    "type": "text_isEmpty"
                },
                {
                    "kind": "BLOCK",
                    "type": "text_indexOf"
                },
                {
                    "kind": "BLOCK",
                    "type": "text_charAt"
                },
                {
                    "kind": "BLOCK",
                    "type": "text_getSubstring"
                },
                {
                    "kind": "BLOCK",
                    "type": "text_changeCase"
                },
                {
                    "kind": "BLOCK",
                    "type": "text_trim"
                },
                {
                    "kind": "BLOCK",
                    "type": "text_print"
                },
                {
                    "kind": "BLOCK",
                    "type": "text_prompt_ext"
                },
                {
                    "kind": "BLOCK",
                    "type": "text_count"
                },
                {
                    "kind": "BLOCK",
                    "type": "text_replace"
                },
                {
                    "kind": "BLOCK",
                    "type": "text_reverse"
                }
            ],
            "name": "Text",
            "colour": BlockColors['WiFi']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "variables_get"
                },
                {
                    "kind": "BLOCK",
                    "type": "variables_set"
                },
            ],
            "custom": "VARIABLE",
            "name": "Variables",
            "colour": BlockColors['Loop']
        },
        {
            "kind": "SEP",
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "play_audio"
                },
                {
                    "kind": "BLOCK",
                    "type": "stop_audio"
                },
                {
                    "kind": "BLOCK",
                    "type": "IPL_audio"
                },
                {
                    "kind": "BLOCK",
                    "type": "INTEL_audio"
                },
                {
                    "kind": "BLOCK",
                    "type": "TRAIN_audio"
                },
                {
                    "kind": "BLOCK",
                    "type": "XPERIA_audio"
                },
                {
                    "kind": "BLOCK",
                    "type": "SEA_WAVES_audio"
                },
            ],
            "name": "Audio",
            "colour": BlockColors['Audio']
        },
    ],
}


const JavascriptBlockToolBoxWithFaceDetection = {}



export default JavascriptBlockToolBox
export {JavascriptBlockToolBoxWithFaceDetection}