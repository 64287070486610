export class JavascriptAudioPlayer{
    audio = new Audio();

    play(fileName:string){
        this.audio.pause();
        this.audio = new Audio(fileName.includes('https') ? fileName : `/audios/${fileName}`);
        this.audio.play();
    }
    stop(){
        this.audio.volume = 0.0;
    }
}


export const audios: { [key: string]: string } = {
    "IPL_audio": "ipl_message.mp3",
    "INTEL_audio": "intel_tone.mp3",
    "TRAIN_audio": "train_time.mp3",
    "XPERIA_audio": "xperia_sms.mp3",
    "SEA_WAVES_audio": "small-waves.mp3",
}


const audioPlayer = new JavascriptAudioPlayer();