import Blockly from "blockly";
import { BlockColors } from "./color-mapping";
import { audios } from "../audio";


Blockly.Blocks['play_audio'] = {
    init: function () {
        this.appendValueInput("audio")
            .appendField("Play Audio");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.Audio);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['stop_audio'] = {
    init: function () {
        this.appendDummyInput("delay")
            .appendField("Stop Audio");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.Audio);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Object.keys(audios).forEach(function (key) {

    Blockly.Blocks[key] = {
        init: function () {
            this.appendDummyInput("delay")
                .appendField(key.replace("_audio", ""));
            this.setOutput(true, "String");
            this.setColour(BlockColors.Audio);
            this.setTooltip("");
            this.setHelpUrl("");
        }
    };
})




export default Blockly