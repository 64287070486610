export const BlockColors = {
    "Section Color": "#DDDDDD",
    "Time": "#84878A",
    "Loop": "#00E300",
    "Variables": "#B200D6",
    "Conditions": "#007DFA",
    "Constants": "#EA0075",
    "Functions": "#205273",
    "Math": "#006400",
    "Audio": "#a3d5ff",
    "Machine": "#d3a1a1",
    "Model": "#f25793",
    "DHT11 Sensor": "#0b7669",
    "Keypad": "#0092a6",
    "RFID Sensor": "#ba6d00",
    "LED": "#990000",
    "Buzzer": "#9f2c79",
    "Motor": "#b76e79",
    "LCD": "#316ccd",
    "WiFi": "#400c16",
    "Bluetooth": "#142c53"
}
